<template>
    <div class="popup-layout">
        <div class="popup popup--6">
            <div class="request">
                <button class="popup__close" type="button" @click="onClose"></button>
                <p class="h2 request__title">
                    Оставить заявку
                </p>
                <div class="form">
                    <p class="colorMirage">
                        Вы уверены что хотите оставить заявку на смену пакета участия с
                        <span class="request__boldText">{{ memberAccessLevel }}</span> на
                        <span class="request__boldText">{{
                            selectedAccessLevel.name
                        }}</span
                        >?
                    </p>

                    <div class="formgroup request__input">
                        <label class="formgroup__label">
                            Имя
                        </label>
                        <Input :inputValue="user.name" type="text" />
                    </div>

                    <div class="formgroup request__input">
                        <label class="formgroup__label">
                            Телефон
                        </label>
                        <phone-mask-input
                            :class="isEmptyPhone ? 'phoneInput' : ''"
                            v-model="user.phone"
                            autoDetectCountry
                            placeholder="Введите ваш номер телефона"
                            wrapperClass="wrraper-example"
                            inputClass="input-example"
                            flagClass="flag-example"
                        />
                        <span class="h5 error errorText" v-if="isEmptyPhone">
                            Введите текст сообщения перед отправкой
                        </span>
                    </div>
                    <div class="form__submit request__buttons">
                        <Button
                            :onClick="() => onClose()"
                            extraClass="button--white"
                            title="отмена"
                        />
                        <Button
                            :onClick="
                                () => {
                                    onValidateInput();
                                }
                            "
                            extraClass="button"
                            title="Подтвердить"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import Input from "@/views/components/Input/Input";
import PhoneMaskInput from "vue-phone-mask-input";
import axios from "axios";

export default {
    name: "requestPopupContent",

    components: { Button, Input, PhoneMaskInput },

    props: ["onClose"],

    async beforeMount() {
        const $this = this;
        await $this.getUserData()
            .then(async (getUserDataResult) => {
                if (getUserDataResult.result) {
                    await $this.$store.dispatch('setFirstName', getUserDataResult.user.name)
                        .then(async () => {
                            await $this.$store.dispatch('setPhone', getUserDataResult.user.phone)
                                .then(async () => {
                                    await $this.getAccessLevelData()
                                        .then(async (getAccessLevelDataResult) => {
                                            if (getAccessLevelDataResult.result) {
                                                $this.memberAccessLevel = getAccessLevelDataResult.memberAccessLevel;
                                                $this.selectedAccessLevel = this.$store.getters.getSelectedAccessLevel;
                                            } else {
                                                await this.$store.dispatch("setAlert", {
                                                    alert: {
                                                        isActive: true,
                                                        type: "error",
                                                        text: "Ошибка при загрузке плана",
                                                    },
                                                });
                                            }
                                        });
                            });
                    });
                } else {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Ошибка при загрузке пользователя",
                        },
                    });
                }
        });
    },

    data() {
        return {
            user: {
                name: this.$store.getters.getUserFirstName || "",
                phone: this.$store.getters.getUserPhone || "",
            },
            memberAccessLevel: "",
            selectedAccessLevel: {},
            isEmptyPhone: false,
        };
    },

    methods: {
        onValidateInput() {
            if (this.user.phone === "") {
                this.isEmptyPhone = true;
            } else {
                this.isEmptyPhone = false;
            }
            if (this.user.phone !== "") {
                this.sendUpSaleRequest();
                this.onClose();
            }
        },

        async getUserData() {
            const getProfileDataResult = await axios.get("/profile/edit");
            if (getProfileDataResult && getProfileDataResult.data && getProfileDataResult.data.result) {
                return {
                    result: true,
                    user: {
                        name: getProfileDataResult.data.user.firstName,
                        phone: getProfileDataResult.data.user.phone
                    }
                };
            } else {
                return {
                    result: false
                };
            }
        },

        async getAccessLevelData() {
            const getAccessLevelsDataResult = await axios.get("/programs/" + this.$route.params.slug + "/study/access_levels");
            if (getAccessLevelsDataResult && getAccessLevelsDataResult.data && getAccessLevelsDataResult.data.result) {
                return {
                    result: true,
                    memberAccessLevel: getAccessLevelsDataResult.data.memberAccessLevel.name
                };
            } else {
                return {
                    result: false
                };
            }
        },

        async sendUpSaleRequest() {
            const requestResult = await axios.post(
                "/programs/" + this.$route.params.slug + "/study/access_levels/upsale",
                { selectedAccessLevel: this.selectedAccessLevel, user: this.user }
            );
            if (requestResult && requestResult.data && requestResult.data.result) {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "success",
                        text: "Заявка отправлена",
                    },
                });
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при отправке заявки",
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "requestPopupContent.scss";
</style>
