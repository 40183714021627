<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/dashboard`"
        />
        <div class="pageSection participationPackages">
            <div class="pageContent pageContent--2">
                <b-skeleton-table
                    v-if="isLoadingData"
                    :rows="8"
                    :columns="4"
                    :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>

                <table v-else>
                    <tr>
                        <td class="td--2 packagesTable__td packagesSpecialOffer">
                            <Banners />
                        </td>
                        <td
                            v-for="(item, index) in this.accessLevelsData"
                            :key="index"
                            :item="item"
                            class="td--2 packagesTable__td"
                        >
                            <p class="h2">{{ item.name }}</p>
                            <p class="packagesActiveItem">
                                <span
                                    :class="
                                        item.access
                                            ? `dashboardPackageCurrent__status ${
                                                  courseStatus === 'active'
                                                      ? 'boxText boxText--green'
                                                      : courseStatus === 'blocked'
                                                      ? 'boxText boxText--orange'
                                                      : courseStatus === 'banned'
                                                      ? 'boxText boxText--red'
                                                      : 'boxText'
                                              }`
                                            : 'hidden'
                                    "
                                >
                                    {{
                                        courseStatus === "active"
                                            ? "Активный"
                                            : courseStatus === "blocked"
                                            ? "Заморожен"
                                            : courseStatus === "banned"
                                            ? "Заблокирован"
                                            : "Неактивный"
                                    }}
                                </span>
                            </p>
                            <p
                                :class="
                                    !item.access && !item.accessAll
                                        ? 'title1 colorGray packagesSubtitle'
                                        : 'hidden'
                                "
                            >
                                {{ item.description }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td class="td--2 packagesTable__td">Тесты для проверки знаний</td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td--2 packagesTable__td">
                            Доступность курса после прохождения
                        </td>
                        <td class="td--2 h4 packagesTable__td">30 дней</td>
                        <td class="td--2 h4 packagesTable__td">Навсегда</td>
                        <td class="td--2 h4 packagesTable__td">Навсегда</td>
                    </tr>
                    <tr>
                        <td class="td--2 packagesTable__td">Бонусные записи вебинаров</td>
                        <td class="td--2 packagesTable__td">-</td>
                        <td class="td--2 h4 packagesTable__td">5 шт</td>
                        <td class="td--2 h4 packagesTable__td">10 шт</td>
                    </tr>
                    <tr>
                        <td class="td--2 packagesTable__td">
                            Проверка алгоритма в конце курса
                        </td>
                        <td class="td--2 packagesTable__td">-</td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td--2 packagesTable__td">
                            Подписка на статистику трейдера
                        </td>
                        <td class="td--2 packagesTable__td">-</td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td--2 packagesTable__td">
                            Проверка сделок по итогу прохождения курса
                        </td>
                        <td class="td--2 packagesTable__td">-</td>
                        <td class="td--2 packagesTable__td">-</td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td--2 packagesTable__td">
                            Вебинары с кураторами
                        </td>
                        <td class="td--2 packagesTable__td">-</td>
                        <td class="td--2 packagesTable__td">-</td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td--2 packagesTable__td">
                            Возможность задавать вопросы экспертам
                        </td>
                        <td class="td--2 packagesTable__td">-</td>
                        <td class="td--2 packagesTable__td">-</td>
                        <td class="td--2 packagesTable__td">
                            <img
                                alt="done"
                                class="packagesImg"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="td--clear packagesTable__clear"></td>
                        <td
                            v-for="(item, index) in this.accessLevelsData"
                            :key="index"
                            :item="item"
                            class="td--clear packagesTable__clear"
                        >
                            <Button
                                :class="
                                    !item.access &&
                                    item.access_level_id >
                                        memberAccessLevel.access_level_id
                                        ? ''
                                        : 'hidden'
                                "
                                :onClick="() => onOpenModalRequest(item)"
                                title="Перейти"
                            />
                        </td>
                    </tr>
                </table>

                <DiscountPopupContent
                    v-if="isVisibleModalDiscount"
                    :onClose="() => onCloseModalDiscount()"
                />
                <RequestPopupContent
                    v-if="isVisibleModalRequest"
                    :onClose="() => onCloseModalRequest()"
                />
            </div>
        </div>
    </Layout>
</template>

<script>
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import Layout from "@/views/layouts/Layout";
import Button from "@/views/components/Button/Button";
import Banners from "@/views/components/Banners/Banners";

import DiscountPopupContent from "@/views/pages/dashboard/participationPackages/discountPopupContent/discountPopupContent";
import RequestPopupContent from "@/views/pages/dashboard/participationPackages/requestPopupContent/requestPopupContent";

export default {
    name: "participationPackages",
    components: {
        PageHeader,
        Layout,
        Button,
        Banners,
        DiscountPopupContent,
        RequestPopupContent,
    },

    async beforeMount() {
        await this.updateAccessLevelsData();
    },

    data() {
        return {
            accessLevelsData: [],
            memberAccessLevel: {},
            title: "Пакеты участия",
            items: [
                {
                    text: "Личный дашборд",
                    to: `/${this.$route.params.slug}/dashboard`,
                },
                {
                    text: "Пакеты участия",
                    active: true,
                },
            ],
            isVisibleModalDiscount: false,
            isVisibleModalRequest: false,
            isLoadingData: false,
        };
    },

    computed: {
        courseStatus() {
            return (
                this.$store.getters.getDashboardActivityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member
                    .status
            );
        },
    },

    methods: {
        onOpenModalDiscount() {
            this.isVisibleModalDiscount = true;
        },
        onCloseModalDiscount() {
            this.isVisibleModalDiscount = false;
        },

        async onOpenModalRequest(accessLevel) {
            this.isVisibleModalRequest = true;
            await this.$store.dispatch("setSelectedAccessLevel", {
                _id: accessLevel._id,
                name: accessLevel.name,
                course: accessLevel.course,
                access_level_id: accessLevel.access_level_id,
            });
        },
        onCloseModalRequest() {
            this.isVisibleModalRequest = false;
        },
        updateAccessLevelsData: async function() {
            this.isLoadingData = true;

            const getAccessLevelsDataResult = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/access_levels"
            );
            if (
                getAccessLevelsDataResult &&
                getAccessLevelsDataResult.data &&
                getAccessLevelsDataResult.data.result
            ) {
                this.accessLevelsData = [];
                this.memberAccessLevel = {};

                for (const access of getAccessLevelsDataResult.data.accesses) {
                    access.access =
                        getAccessLevelsDataResult.data.memberAccessLevel._id ===
                        access._id;

                    this.accessLevelsData.push(access);
                }

                this.memberAccessLevel = getAccessLevelsDataResult.data.memberAccessLevel;
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при загрузке данных пакетов",
                    },
                });
            }
            this.isLoadingData = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "participationPackages.scss";
</style>
