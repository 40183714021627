<template>
    <div class="popup-layout">
        <div class="popup popup--6 popup--noPadd discountPopup">
            <img
                class="discountPopup__img"
                alt="done"
                src="@/assets/img/pages/dashboard/participationPackages/image.png"
            />
            <div class="discountPopupContent">
                <button type="button" class="popup__close" @click="onClose"></button>
                <p class="h2 discountPopupContent__title">
                    Скидка на пакет Расширенный
                </p>
                <div>
                    <p class="colorFiord discountPopupContent__text">
                        Чтобы получить скидку для перехода на пакет Расширенный, Вам
                        осталось набрать <span class="colorYellow">150 баллов</span>.
                    </p>
                    <p class="colorFiord discountPopupContent__text">
                        Подробнее о возможностях перехода на другие пакеты Вы можете
                        узнать у своего координатора обучения.
                    </p>
                    <div class="discountPopupContent__button">
                        <Button
                            title="получить скидку"
                            extraClass="button"
                            :onClick="() => onClose()"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "discountPopupContent",
    components: { Button },
    props: ["onClose"],
    data() {
        return {};
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
@import "discountPopupContent.scss";
</style>
